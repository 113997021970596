<template>
  <!-- Main content -->
  <section class="section">
    <div class="row center-block">
      <div class="col-md-12">
        <div class="box">
          <div class="box-body">
            <form @submit.prevent>
              <div class="input-group">
                <span class="input-group-addon input-group" aria-hidden="true">
                  <select v-model="locale" class="form-control input-group" style="min-width: 150px;">
                    <option value="en_US">🇺🇸 American English</option>
                    <option value="en_GB">🇬🇧 British English</option>
                    <option value="fr_FR">🇫🇷 French</option>
                    <option value="de_DE">🇩🇪 German</option>
                    <option value="pt_BR">🇧🇷 Portuguese (Brazil)</option>
                    <option value="pt_PT">🇵🇹 Portuguese (Portugal)</option>
                    <option value="es_ES">🇪🇸 Spanish</option>
                    <option value="cy_GB">🏴󠁧󠁢󠁷󠁬󠁳󠁿 Welsh</option>
                  </select>
                </span>
                <input
                  id="inputSearch"
                  v-model="searchPhrase"
                  type="text"
                  class="form-control"
                  placeholder="Search..."
                  autocomplete="off"
                  @keyup="search"
                >
                <span class="input-group-addon" aria-hidden="true"><i id="searchIndicator" class="fa fa-search" /></span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div v-if="listData && listData.length > 0" class="row center-block">
      <div class="col-md-12">
        <div class="box box-info">
          <div class="box-body">
            <b-table
              v-if="listData"
              :data.sync="listData"
              :paginated="true"
              :per-page="10"
              :mobile-cards="true"
              :striped="true"
              :selectable="false"
              :sort="false"
            >
              <template slot-scope="props">
                <b-table-column field="name" label="Name" item-width="10px" sortable>
                  <h4>{{ props.row.title }} </h4><p>
                    <router-link :to="'/users/' + props.row.owner">
                      <i>@{{ props.row.owner }}</i>
                    </router-link>
                  </p><p>{{ props.row.word_count || 0 }} Word(s) <span v-if="props.row.favourite">❤️</span></p>
                </b-table-column>
                <b-table-column field="rating" label="Rating" sortable>
                  <span style="display:none">props.row.rating</span>
                  <star-rating
                    v-model="props.row.rating"
                    :item-size="18"
                    active-color="#ffdf00"
                    border-color="transparent"
                    :spacing="-5"
                    :read-only="true"
                    :show-rating="false"
                    :inline="true"
                  />
                </b-table-column>
                <b-table-column field="actions" label="">
                  <div class="pull-right">
                    <router-link tag="button" :to="'/list/' + props.row.ident" class="btn button is-link" type="button">
                      Details
                    </router-link>&nbsp;
                    <button v-if="props.row.fav == 1 && props.row.owner != currentUsername" class="btn button is-link" type="button" @click.prevent="removeFavourite(props.row)">
                      Un-{{ $store.state.user.locale === 'en_US' ? 'Favorite' : 'Favourite' }} ❤️
                    </button>
                    <button v-else-if="props.row.owner != currentUsername" class="btn button" type="button" @click.prevent="addFavourite(props.row)">
                      Add {{ $store.state.user.locale === 'en_US' ? 'Favorite' : 'Favourite' }} ❤️
                    </button>
                  </div>
                </b-table-column>
              </template>
              <template slot="empty">
                <section class="section">
                  <div class="content has-text-grey has-text-centered content">
                    <p>
                      <b-icon
                        custom-class="far"
                        pack="fa"
                        icon="frown"
                        size="is-large"
                      />
                    </p>
                    <p>Nothing here.</p>
                  </div>
                </section>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="response != ''" class="alert alert-info">
      <b>{{ response }}</b>
    </div>
  </section>
  <!-- /.content -->
</template>

<script>

import $ from 'jquery'
import { StarRating } from 'vue-rate-it'
import { request } from '@/edshed-common/api'

export default {
  name: 'SearchLists',
  components: {
    StarRating
  },
  data (router) {
    return {
      loading: '',
      searchPhrase: '',
      listData: null,
      response: '',
      locale: this.$store.state.user.school.locale,
      currentUsername: this.$store.state.user.username
    }
  },
  methods: {
    search (t) {
      this.listData = null

      if (t.srcElement.value === '') {
        this.response = ''
        return
      }
      if (t.srcElement.value.length < 3) {
        this.response = 'Minimum 3 characters'
        return
      }
      $('#searchIndicator').removeClass('fa-search').addClass('fa-spinner')
      this.toggleLoading()
      this.$store.commit('TOGGLE_LOADING')
      request('GET', 'search?q=' + t.srcElement.value + '&locale=' + this.locale, null)
        .then((response) => {
          $('#searchIndicator').removeClass('fa-spinner').addClass('fa-search')
          this.listData = null

          const data = response.data
          this.listData = data.lists.map(this.numberIfy)
          // console.log('readerData: ' + this.readerData)
          if (!this.listData || this.listData.length === 0) {
            this.response = 'No Lists'
          }
          this.toggleLoading()
          this.$store.commit('TOGGLE_LOADING')
        })
        .catch((error) => {
          console.log(error)
          this.$store.commit('TOGGLE_LOADING')
          this.toggleLoading()
          if (error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }
          this.response = 'Details incorrect'
        })
    },
    addFavourite (list) {
      request('PUT', 'lists/' + list.ident + '/favourite', null)
        .then((response) => {
          list.fav = 1
        })
        .catch((error) => {
          console.log(error)
          this.$store.commit('TOGGLE_LOADING')
          this.toggleLoading()
          if (error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }
          this.response = 'Details incorrect'
        })
    },
    removeFavourite (list) {
      const confirm = window.confirm('Are you sure you want to remove this list?')
      if (confirm) {
        request('DELETE', 'lists/' + list.ident + '/favourite', null)
          .then((response) => {
            list.fav = 0
          })
          .catch((error) => {
            console.log(error)
            this.$store.commit('TOGGLE_LOADING')
            this.toggleLoading()
            if (error.response.status === 403) {
              console.log('FORBIDDEN')
              this.$router.push('/logout')
            }
            this.response = 'Details incorrect'
          })
      }
    },
    numberIfy (item, index) {
      item.rating = parseFloat(item.rating)
      return item
    },
    toggleLoading () {
      this.loading = (this.loading === '') ? 'loading' : ''
    }
  }
}
</script>
<style>
#inputSearch {
  font-size:16px;
}
</style>
